import { clsx } from 'clsx'
import { Fragment, useState } from 'react'

interface Clinic {
  id: string
  name: string
  address: string
  location: {
    lat: number
    lng: number
  }
  workingHours: Array<{
    label: string
    value: string
  }>
  email: string
  phones: Array<{
    label: string
    value: string
  }>
}

let clinics: Clinic[] = [
  {
    id: '1',
    name: 'Клиника в Купчино',
    address: 'Улица Ярослава Гашека, дом 5, ТК Балатон',
    location: {
      lat: 59.8489,
      lng: 30.3769
    },
    workingHours: [
      {
        label: 'пн.-пт.',
        value: '08:00–21:00'
      },
      {
        label: 'сб.-вс.',
        value: '09:00–18:00'
      }
    ],
    email: '704@baltzdrav.ru',
    phones: [
      {
        label: '+7 (812) 325-77-88',
        value: '+78123257788'
      },
      {
        label: '+7 (911) 201-11-33',
        value: '+79112011133'
      }
    ]
  },
  {
    id: '2',
    name: 'Клиника в Купчино',
    address: 'Улица Ярослава Гашека, дом 5, ТК Балатон',
    location: {
      lat: 59.8489,
      lng: 30.3769
    },
    workingHours: [
      {
        label: 'пн.-пт.',
        value: '08:00–21:00'
      },
      {
        label: 'сб.-вс.',
        value: '09:00–18:00'
      }
    ],
    email: '704@baltzdrav.ru',
    phones: [
      {
        label: '+7 (812) 325-77-88',
        value: '+78123257788'
      },
      {
        label: '+7 (911) 201-11-33',
        value: '+79112011133'
      }
    ]
  },
  {
    id: '3',
    name: 'Клиника в Купчино',
    address: 'Улица Ярослава Гашека, дом 5, ТК Балатон',
    location: {
      lat: 59.8489,
      lng: 30.3769
    },
    workingHours: [
      {
        label: 'пн.-пт.',
        value: '08:00–21:00'
      }
    ],
    email: '704@baltzdrav.ru',
    phones: [
      {
        label: '+7 (812) 325-77-88',
        value: '+78123257788'
      },
      {
        label: '+7 (911) 201-11-33',
        value: '+79112011133'
      }
    ]
  },
  {
    id: '4',
    name: 'Клиника в Купчино',
    address: 'Улица Ярослава Гашека, дом 5, ТК Балатон',
    location: {
      lat: 59.8489,
      lng: 30.3769
    },
    workingHours: [
      {
        label: 'пн.-пт.',
        value: '08:00–21:00'
      },
      {
        label: 'сб.-вс.',
        value: '09:00–18:00'
      }
    ],
    email: '704@baltzdrav.ru',
    phones: [
      {
        label: '+7 (911) 201-11-33',
        value: '+79112011133'
      }
    ]
  },
  {
    id: '5',
    name: 'Клиника в Купчино',
    address: 'Улица Ярослава Гашека, дом 5, ТК Балатон',
    location: {
      lat: 59.8489,
      lng: 30.3769
    },
    workingHours: [
      {
        label: 'пн.-пт.',
        value: '08:00–21:00'
      }
    ],
    email: '704@baltzdrav.ru',
    phones: [
      {
        label: '+7 (812) 325-77-88',
        value: '+78123257788'
      }
    ]
  },
  {
    id: '6',
    name: 'Клиника в Купчино',
    address: 'Улица Ярослава Гашека, дом 5, ТК Балатон',
    location: {
      lat: 59.8489,
      lng: 30.3769
    },
    workingHours: [
      {
        label: 'пн.-пт.',
        value: '08:00–21:00'
      },
      {
        label: 'сб.-вс.',
        value: '09:00–18:00'
      }
    ],
    email: '704@baltzdrav.ru',
    phones: [
      {
        label: '+7 (812) 325-77-88',
        value: '+78123257788'
      },
      {
        label: '+7 (911) 201-11-33',
        value: '+79112011133'
      }
    ]
  }
]

export function ClinicsMap(): React.ReactNode {
  return (
    <div className='flex flex-col gap-4 py-4'>
      <h2 className='px-4-safe text-2xl lg:container lg:mx-auto'>Адреса клиник</h2>

      <div className='h-80 w-full bg-blue-200'>{/* // */}</div>

      <ClinicsTable />
      <ClinicsHorizontalList />
    </div>
  )
}

function ClinicsTable(): React.ReactNode {
  return (
    <div className='hidden grid-cols-[1fr_2fr_1fr_1fr_1fr] gap-x-4 gap-y-6 lg:container lg:mx-auto lg:grid'>
      {clinics.map(clinic => (
        <Fragment key={clinic.id}>
          <h3 className='font-semibold'>{clinic.name}</h3>

          <p>{clinic.address}</p>

          <div className='grid grid-cols-[max-content_1fr] gap-x-2'>
            {clinic.workingHours.map(entry => (
              <Fragment key={entry.label}>
                <span>{entry.label}</span>

                <span>{entry.value}</span>
              </Fragment>
            ))}
          </div>

          <div className='flex flex-col'>
            {clinic.phones.map(phone => (
              <a
                key={phone.label}
                href={`tel:${phone.value}`}
                className='decoration-gray-300 underline-offset-4 hover:underline'
              >
                {phone.label}
              </a>
            ))}
          </div>

          <a
            href={`mailto:${clinic.email}`}
            className='decoration-gray-300 underline-offset-4 hover:underline'
          >
            {clinic.email}
          </a>

          <div className='line w-full border-b border-gray-200 [&]:[grid-column:1_/_-1]' />
        </Fragment>
      ))}
    </div>
  )
}

function ClinicsHorizontalList(): React.ReactNode {
  let [activeIndex, setActiveIndex] = useState(0)

  return (
    <div className='px-4-safe flex w-full flex-col gap-4 lg:hidden'>
      <div
        className='no-scrollbar flex gap-4 overflow-x-auto'
        onScroll={event => {
          let target = event.target as HTMLElement
          let scrollLeft = target.scrollLeft + /* window.innerWidth / 2 */ 100
          let scrollWidth = target.scrollWidth

          let activeIndex = Math.max(
            0,
            Math.min(clinics.length - 1, Math.floor(scrollLeft / (scrollWidth / clinics.length)))
          )
          setActiveIndex(activeIndex)
        }}
      >
        {clinics.map(clinic => (
          <div
            key={clinic.id}
            className='flex w-[75vw] max-w-60 shrink-0 flex-col gap-3 rounded border border-gray-200 p-4'
          >
            <h3 className='text-lg font-semibold'>{clinic.name}</h3>

            <p>{clinic.address}</p>

            <div className='grid grid-cols-[max-content_1fr] gap-x-2'>
              {clinic.workingHours.map(entry => (
                <Fragment key={entry.label}>
                  <span>{entry.label}</span>

                  <span>{entry.value}</span>
                </Fragment>
              ))}
            </div>

            <div className='flex flex-col'>
              {clinic.phones.map(phone => (
                <a
                  key={phone.label}
                  href={`tel:${phone.value}`}
                  className='decoration-gray-300 underline-offset-4 hover:underline'
                >
                  {phone.label}
                </a>
              ))}
            </div>

            <a
              href={`mailto:${clinic.email}`}
              className='decoration-gray-300 underline-offset-4 hover:underline'
            >
              {clinic.email}
            </a>
          </div>
        ))}
      </div>

      <ScrollIndicator
        activeIndex={activeIndex}
        totalItems={clinics.length}
      />
    </div>
  )
}

interface ScrollIndicatorProps {
  activeIndex: number
  totalItems: number
}

function ScrollIndicator({ activeIndex, totalItems }: ScrollIndicatorProps): React.ReactNode {
  return (
    <div className='flex justify-center gap-2'>
      {Array.from({ length: totalItems }, (_, index) => (
        <div
          key={index}
          className={clsx(
            'h-[2px] w-4 rounded transition-colors',
            index === activeIndex ? 'bg-red-500' : 'bg-gray-200'
          )}
        />
      ))}
    </div>
  )
}
