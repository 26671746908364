export function FAQ(): React.ReactNode {
  return (
    <div className='h-96 bg-gray-100'>
      <div className='px-4-safe flex flex-col gap-4 py-8 lg:container lg:mx-auto'>
        <h2 className='text-2xl'>Часто задаваемые вопросы</h2>

        <p>Здесь мы подготовили для вас ответы на самые популярные вопросы.</p>
      </div>
    </div>
  )
}
