import heroImageUrl from '../assets/images/hero-2.jpg.webp'
import { ClinicsMap } from '../components/clinics-map.js'
import { FAQ } from '../components/faq.js'
import { Footer } from '../components/footer.js'
import { Header } from '../components/header.js'
import { PageContainer } from '../components/page-container.js'

export default function IndexPage(): React.ReactNode {
  return (
    <PageContainer>
      <Header />

      <main className='flex grow flex-col'>
        <div
          className='relative bg-cover bg-center text-white'
          style={{
            backgroundImage: `url(${heroImageUrl})`
          }}
        >
          <div className='absolute inset-0 z-0 bg-[#ccccdf]/40' />
          <div className='absolute inset-0 z-0 bg-black/30' />

          <div className='px-4-safe relative z-20 flex w-full flex-col items-center justify-center gap-4 py-36 lg:container lg:mx-auto'>
            <h1 className='font-display text-center text-4xl font-semibold'>Запись на приём к врачу</h1>

            <p className='text-lg'>Консультация в клинике. Очный приём.</p>

            <a
              href='/appointments/search'
              className='rounded bg-red-500 px-12 py-4 text-lg text-white'
            >
              Записаться на приём
            </a>
          </div>
        </div>

        <div className='px-4-safe flex flex-col gap-4 py-8 lg:container lg:mx-auto'>
          <h2 className='text-2xl lg:text-center'>Перенос или отмена записи</h2>

          <p className='text-gray-600 lg:text-center'>Выберите другое время или отмените прием онлайн.</p>

          <form className='flex flex-col gap-4 lg:flex-row lg:justify-stretch lg:gap-0'>
            <input
              type='text'
              placeholder='Фамилия, имя, отчество пациента'
              className='form-input grow rounded-lg border border-gray-300 p-4 lg:rounded-none lg:rounded-l-lg lg:border-r-0'
              autoComplete='name'
              required
            />

            <input
              type='tel'
              placeholder='Телефон'
              className='form-input grow rounded-lg border border-gray-300 p-4 lg:rounded-none lg:border-r-0'
              autoComplete='tel'
              required
            />

            <div className='relative grow'>
              <input
                type='date'
                placeholder='Дата рождения'
                className='form-input peer w-full appearance-none rounded-lg border border-gray-300 bg-transparent p-4 lg:rounded-none lg:border-r-0'
                autoComplete='bday'
                required
              />

              <span className='ios:block pointer-events-none absolute left-0 top-0 hidden size-full p-4 text-gray-500 peer-valid:hidden peer-focus:hidden peer-focus-visible:hidden peer-active:hidden'>
                Дата рождения
              </span>
            </div>

            <button
              type='submit'
              className='shrink-0 rounded bg-red-500 p-4 text-white lg:rounded-none lg:rounded-r-lg lg:border lg:border-red-500 lg:bg-transparent lg:text-red-500'
            >
              Найти запись
            </button>
          </form>
        </div>
      </main>

      <div>
        <FAQ />
        <ClinicsMap />
      </div>

      <Footer />
    </PageContainer>
  )
}
